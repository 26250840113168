<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>采购管理</el-breadcrumb-item>
      <el-breadcrumb-item>供应商业务管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div class="top">
        <div class="title">
          <div class="title-one">供应商</div>
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入供应商名称"
            v-model="supplier"
            :fetch-suggestions="validateCounts"
            value-key="supplier"
            @select="handleSelect($event, '供应商')"
            clearable
            @clear="handleEmpty"
          ></el-autocomplete>
        </div>
        <div class="title">
          <div class="title-one">供应货物类型</div>
          <el-select v-model="type">
            <el-option v-for="item in trashtypeData" :key="item.label" :label="item.label" :value="item.value" @click.native="typechange(item.value)"></el-option>
          </el-select>
        </div>
        <div class="title">
          <div class="title-one">日期筛选</div>
          <el-date-picker
            :picker-options="pickerOptions"
            @change="gettime"
            v-model="timeFrom"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>

      <div class="foot">
        <el-button style="margin-right: 15px" type="primary" @click="fujilu">付款申请记录</el-button>
        <el-button style="margin-right: 15px" type="primary" @click="huijilu">回票记录</el-button>
      </div>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="no" label="供应商编号" width="180"></el-table-column>
        <el-table-column prop="supplier" label="供应商名称" width="180"></el-table-column>
        <el-table-column prop="type_name" label="供应货物类型"></el-table-column>
        <el-table-column prop="cooperation_count" label="累计合作次数" align="center"></el-table-column>
        <el-table-column prop="cooperation_money" label="累计合作金额" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.cooperation_money / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="unsettle_money" label="未结账款金额" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.unsettle_money / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="overtime_money" label="逾期账款金额" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.unsettle_money / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="current_month_money" label="当月应结金额" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.current_month_money / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="260">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="xinfubtn(scope.row.id)">新增付款申请</el-button>
            <el-button size="mini" type="success" @click="xinhuibtn(scope.row.id)">新增回票</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <!-- 新增付款申请弹窗 -->
    <el-dialog title="新增付款申请" :visible.sync="xinfuVisible" :before-close="xinmoren" width="70%">
      <div class="tans">
        <div class="let">供应商</div>
        <div class="el-select"><el-input v-model="gongxiang.name" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">开户银行</div>
        <div class="el-select"><el-input v-model="gongxiang.bank" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">银行账号</div>
        <div class="el-select"><el-input v-model="gongxiang.cardno" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">税号</div>
        <div class="el-select"><el-input v-model="gongxiang.tax_no" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">选择审批人</div>
        <el-select v-model="dform.auth_user" placeholder="请选择审批人" clearable>
          <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </div>
      <el-table :data="neifulist" ref="table" stripe style="width: 100%" @selection-change="handleSelectionChangezz">
        <el-table-column type="selection" width="55" align="center" :selectable="selectable" />
        <el-table-column prop="purchase_no" label="采购单编号" width="150"></el-table-column>
        <el-table-column prop="date" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="purchase_user" label="采购人"></el-table-column>
        <el-table-column prop="goods_info" label="采购物品"></el-table-column>
        <el-table-column prop="contract_amount" label="采购总金额" align="center"></el-table-column>
        <el-table-column prop="payed_amount" label="已付款金额" align="center"></el-table-column>
        <el-table-column prop="need_pay_amount" label="未付款金额" align="center"></el-table-column>
        <el-table-column prop="pay_amount" label="费用金额(元)" width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.pay_amount" min="0" placeholder="请输入费用金额" @input="scope.row.pay_amount = feiinputCheck(scope.row.pay_amount)"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="xinfuquxiao()">取 消</el-button>
        <el-button type="primary" @click="xinfuque()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 新增回票弹窗 -->
    <el-dialog title="新增回票信息" :visible.sync="xinhuiVisible" :before-close="huimoren" width="70%">
      <div class="tans">
        <div class="let">供应商</div>
        <div class="el-select"><el-input v-model="gongxiang.name" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">开户银行</div>
        <div class="el-select"><el-input v-model="gongxiang.bank" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">银行账号</div>
        <div class="el-select"><el-input v-model="gongxiang.cardno" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">税号</div>
        <div class="el-select"><el-input v-model="gongxiang.tax_no" disabled></el-input></div>
      </div>
      <div class="tans">
        <div class="let">发票类型</div>
        <el-select v-model="huiform.bill_type" placeholder="请选择发票种类" clearable>
          <el-option v-for="item in fapiao" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="tans">
        <div class="let">选择审批人</div>
        <el-select v-model="huiform.auth_user" placeholder="请选择审批人" clearable>
          <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="tans">
        <div class="let">回票截图</div>
        <el-upload
          accept=".pdf, .jpg, .jpeg, .png"
          class="upload-demo"
          action=""
          :on-preview="zhengendhandlePreview"
          :on-remove="
            (file, fileList) => {
              huihandleRemove(file, fileList, this.goumai_fapiaos)
            }
          "
          :before-remove="huibeforeRemove"
          :http-request="huihttpRequest"
          :multiple="false"
          :limit="1"
          :on-exceed="zhengendhandleExceed"
          :file-list="goumai_fapiaos"
        >
          <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('发票附件')">点击上传</el-button>
        </el-upload>
      </div>
      <el-table :data="neifulist" ref="table" stripe style="width: 100%" @selection-change="huihandleSelectionChangezz">
        <el-table-column type="selection" width="55" align="center" :selectable="huiselectable" />
        <el-table-column prop="purchase_no" label="采购单编号" width="150"></el-table-column>
        <el-table-column prop="date" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="purchase_user" label="采购人"></el-table-column>
        <el-table-column prop="goods_info" label="采购物品"></el-table-column>
        <el-table-column prop="contract_amount" label="采购总金额" align="center"></el-table-column>
        <el-table-column prop="returned_amount" label="已开票金额" align="center"></el-table-column>
        <el-table-column prop="need_return_amount" label="未开票金额" align="center"></el-table-column>
        <el-table-column prop="return_money" label="费用金额(元)" width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.return_money" min="0" placeholder="请输入费用金额" @input="scope.row.return_money = feiinputCheck(scope.row.return_money)"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="xinhuiquxiao()">取 消</el-button>
        <el-button type="primary" @click="xinhuique()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 付款申请记录 -->
    <el-dialog title="付款申请记录" :z-index="1000" :visible.sync="fujiluVisible" :before-close="fuguan" width="70%" center>
      <div class="sors">
        <div class="tishi">除审核状态之外其他条件必选一个</div>
        <el-button type="primary" @click="chongxuan">重新选择</el-button>
      </div>
      <div class="top-box">
        <div class="box-left">
          <div class="title">
            <div class="title-ones">审批状态</div>
            <el-select v-model="auth_status">
              <el-option v-for="item in zhuangtai" :key="item.label" :label="item.label" :value="item.value" @click.native="chazhuang(item.value)"></el-option>
            </el-select>
          </div>

          <div class="title">
            <div class="title-ones">申请人</div>
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入内容"
              v-model="shenuser"
              :fetch-suggestions="shenvalidateCounts"
              value-key="name"
              @select="shenhandleSelect($event, '申请人')"
            ></el-autocomplete>
          </div>
          <div class="title">
            <div class="title-ones">付款人</div>
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入内容"
              v-model="fuuser"
              :fetch-suggestions="caivalidateCounts"
              value-key="name"
              @select="caihandleSelect($event, '采购人')"
            ></el-autocomplete>
          </div>
        </div>
        <div class="box-right">
          <div class="title">
            <div class="title-ones">供应商</div>
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入供应商名称"
              v-model="fusupplier"
              :fetch-suggestions="validateCounts"
              value-key="supplier"
              @select="fuhandleSelect($event, '供应商')"
              clearable
              @clear="handleEmpty"
            ></el-autocomplete>
          </div>
          <div class="title">
            <div class="title-ones">日期筛选</div>
            <el-date-picker
              @change="fugettime"
              v-model="futimeFrom"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div class="title">
            <div class="title-ones">付款金额</div>
            <div class="title-two">
              <div class="shai">
                <el-input v-model="jine1" placeholder="开始金额" @input="qian(jine1)" type="number"></el-input>
              </div>
              <span>~</span>
              <div class="shai">
                <el-input v-model="jine2" placeholder="结束金额" @input="hou(jine2)" type="number"></el-input>
              </div>
              <el-button slot="append" icon="el-icon-search" @click="sosuofun"></el-button>
            </div>
          </div>
        </div>
      </div>

      <el-table :data="futableData" stripe style="width: 100%">
        <el-table-column prop="pay_date" label="付款时间" width="180"></el-table-column>
        <el-table-column prop="supplier_name" label="供应商名称" width="180"></el-table-column>
        <el-table-column prop="apply_user_name" label="申请人"></el-table-column>
        <el-table-column prop="auth_user_name" label="审核人"></el-table-column>
        <el-table-column prop="pay_user_name" label="付款人"></el-table-column>
        <el-table-column prop="pay_amount" label="付款金额(元)"></el-table-column>
        <el-table-column label="支付图片" width="90" class="picsya">
          <template slot-scope="scope">
            <span class="span" v-if="scope.row.images.length !== 1">+{{ scope.row.images.length }}</span>
            <el-image style="width: 50px; height: 50px" :src="scope.row.images[0]" :preview-src-list="scope.row.images"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="auth_status_desc" label="审批状态"></el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="fukuanshen(scope.row.id)">通 过</el-button>
            <el-button size="mini" type="danger" @click="fukuanju(scope.row.id)">拒 绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 付款记录内部审核通过弹窗 -->
      <el-dialog width="40%" title="付款审核" :visible.sync="fukuanshenheVisible" :before-close="fushenmorenguan" append-to-body>
        <el-form :model="fushenform">
          <el-form-item label="审核备注" label-width="140px">
            <div class="el-select"><el-input v-model="fushenform.remark" placeholder="请输入审核备注"></el-input></div>
          </el-form-item>
          <el-form-item label="下一个审批人" label-width="140px">
            <el-select v-model="fushenform.next_user" placeholder="未选择" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="付款单图片" label-width="140px">
            <el-upload
              accept=".pdf, .jpg, .jpeg, .png"
              class="upload-demo"
              action=""
              :on-preview="zhengendhandlePreview"
              :on-remove="
                (file, fileList) => {
                  fushenhandleRemove(file, fileList, abc)
                }
              "
              :before-remove="fubeforeRemove"
              :http-request="fushenhttpRequest"
              :multiple="false"
              :limit="3"
              :on-exceed="zhengendhandleExceed"
              :file-list="abc"
            >
              <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('发票附件')">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="fushenquxiao">取 消</el-button>
          <el-button type="primary" @click="fushenqueding">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 拒绝弹窗 -->
      <el-dialog width="30%" title="付款审核" :visible.sync="fujuvisible" :before-close="fujumorenguan" append-to-body>
        <div class="tans">
          <div class="let">审核备注</div>
          <div class="el-select"><el-input v-model="fujuremark" placeholder="请输入审核备注"></el-input></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="fujuquxiao()">取 消</el-button>
          <el-button type="primary" @click="fujuqueding()">确定</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="fulistguanbi()">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 回票记录 -->
    <el-dialog title="回票记录" :visible.sync="huijiluVisible" :before-close="huimorenguanbi" :z-index="1000" width="70%" center>
      <div class="sors">
        <div class="tishi">除审核状态之外其他条件必选一个</div>
        <el-button type="primary" @click="huichongxuan">重新选择</el-button>
      </div>
      <div class="top-box">
        <div class="box-left">
          <div class="title">
            <div class="title-ones">审批状态</div>
            <el-select v-model="huiauth_status">
              <el-option v-for="item in zhuangtai" :key="item.label" :label="item.label" :value="item.value" @click.native="huizhuang(item.value)"></el-option>
            </el-select>
          </div>
          <div class="title">
            <div class="title-ones">供应商</div>
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入供应商名称"
              v-model="huisupplier"
              :fetch-suggestions="huivalidateCounts"
              value-key="supplier"
              @select="huihandleSelect($event, '供应商')"
              clearable
            ></el-autocomplete>
          </div>
          <div class="title">
            <div class="title-ones">申请人</div>
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入内容"
              v-model="huishenuser"
              :fetch-suggestions="huisvalidateCounts"
              value-key="name"
              @select="huishenhandleSelect($event, '申请人')"
            ></el-autocomplete>
          </div>
        </div>
        <div class="box-right">
          <div class="title">
            <div class="title-ones">日期筛选</div>
            <el-date-picker
              @change="huigettime"
              v-model="huitimeFrom"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div class="title">
            <div class="title-ones">付款金额</div>
            <div class="title-two">
              <div class="shai">
                <el-input v-model="huijine1" placeholder="开始金额" @input="huiqian(huijine1)" type="number"></el-input>
              </div>
              <span>~</span>
              <div class="shai">
                <el-input v-model="huijine2" placeholder="结束金额" @input="huihou(huijine2)" type="number"></el-input>
              </div>
              <el-button slot="append" icon="el-icon-search" @click="huisosuofun"></el-button>
            </div>
          </div>
        </div>
      </div>
      <el-table :data="huitableData" stripe style="width: 100%">
        <el-table-column prop="return_date" label="付款时间"></el-table-column>
        <el-table-column prop="supplier_name" label="供应商名称"></el-table-column>
        <el-table-column prop="apply_user_name" label="申请人"></el-table-column>
        <el-table-column prop="auth_user_name" label="审核人"></el-table-column>
        <el-table-column prop="return_amount" label="回票金额(元)"></el-table-column>
        <el-table-column label="支付图片" class="picsya">
          <template slot-scope="scope">
            <span class="span" v-if="scope.row.images.length !== 1">+{{ scope.row.images.length }}</span>
            <el-image style="width: 50px; height: 50px" :src="scope.row.images[0]" :preview-src-list="scope.row.images"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="auth_status_desc" label="审批状态"></el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="huikuanshen(scope.row.id)">通 过</el-button>
            <el-button size="mini" type="danger" @click="huikuanju(scope.row.id)">拒 绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 回票审核通过弹窗 -->
      <el-dialog title="审核" :visible.sync="loshenss" width="40%" :before-close="tongmoren" append-to-body>
        <div class="tans">
          <div class="lets">审核备注</div>
          <div class="el-select"><el-input v-model="huitongremark" placeholder="请输入审核备注"></el-input></div>
        </div>

        <div class="tans">
          <div class="lets">下一个审核人</div>
          <div class="el-select">
            <el-select v-model="huitongnext_user" placeholder="未选择" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="huitongqu">取 消</el-button>
          <el-button type="primary" @click="huitongque">确定</el-button>
        </span>
      </el-dialog>

      <!--  不通过弹窗 -->
      <el-dialog title="审核" :visible.sync="noshen" width="40%" :before-close="shennotongClose" append-to-body>
        <div class="tans">
          <div class="let">审核备注</div>
          <div class="el-select"><el-input v-model="huinoremark" placeholder="请输入审核备注"></el-input></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="notongquxiao()">取 消</el-button>
          <el-button type="primary" @click="noaddgoto()">确定</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="huilistguanbi()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { Upimg } from '@/api/upimg'
import { Type, Drop } from '../../../api/slogong'
import { Gongyewu, Gongsou, Caidanfu, Caifushen, Caidanhui, Caihuishen } from '../../../api/gongyewu'
import { Caigoudanlist, Shenheren, Xiangfukuan, huipiaotijiao } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      addimgtype: false, // 上传控制
      tableData: [], // 供应商业务列表数据
      supplier: '', // 供应给id
      userlist: [], // 供应商迷糊搜索数据
      type: '', // 供应货物类型
      trashtypeData: [], // 供应货物类型
      timeFrom: [], // 日期选中数据
      from_time: '', // 开始时间
      end_time: '', // 结束时间
      xinfuVisible: false, // 新增付款申请弹窗开关
      xinhuiVisible: false, // 新增回票弹窗
      fujiluVisible: false, // 付款记录弹窗开关
      huijiluVisible: false, // 回票记录弹窗开关
      gongxiang: {}, // 供应商卡号
      neifulist: [], // 新建付款申请弹窗内的表格
      renlist: [], // 人员列表
      goumai_fapiaos: [], // 上传截图存储
      supplier_id: '',
      fusupplier: '',
      fusupplier_id: '',
      futimeFrom: [], // 日期
      fufrom_time: '', // 开始事件
      fuend_time: '', // 结束时间
      fuuser: '', // 付款记录申请人模糊搜索
      apply_user: '',
      pay_user: '',
      shenuser: '',
      jine1: '',
      jine2: '',
      from_amount: '', // 开始付款金额
      end_amount: '', // 结束付款金额
      auth_status: '-1', // 审批状态
      caigoulist: [], // 申请人模糊搜索
      futableData: [], // 付款记录数据
      dform: {
        supplier_id: '',
        auth_user: '',
        pay_list: []
      },
      huiform: {
        supplier_id: '',
        auth_user: '',
        return_list: [],
        bill_type: '',
        images: ''
      },
      fapiao: [
        { name: '请选择', value: '' },
        { name: '普通发票', value: 1 },
        { name: '增值税发票', value: 2 }
      ],

      zhuangtai: [
        { label: '全部', value: '-1' },
        { label: '待审核', value: '0' },
        { label: '完成', value: '1' },
        { label: '审核不通过', value: '2' },
        { label: '正在审批中 ', value: '3' }
      ],

      // 付款审核数据
      fushenform: {
        id: '',
        status: '',
        remark: '',
        next_user: '',
        images: []
      },
      abc: [],
      fukuanshenheVisible: false, // 付款审核弹窗
      fujuvisible: false, // 付款拒绝弹窗
      fujuid: '', // 拒绝id
      fujuremark: '', // 拒绝备注
      fujustatus: '', // 拒绝字段
      /////////////////////////////
      huiauth_status: '-1',
      huishenuser: '',
      huisupplier: '',
      huitimeFrom: [],
      huifrom_time: '',
      huiend_time: '',
      huifrom_amount: '',
      huiend_amount: '',
      huijine1: '',
      huijine2: '',
      huigongyingid: '',
      huishenapply_user: '',
      huitableData: [],
      loshenss: false, // 回票审核通过弹窗
      huitongremark: '', // 通过备注
      huitongnext_user: '', // 通过下一步审核人
      huitongid: '', // 审核通过id
      huitongstatus: '1', // 通过状态
      noshen: false,
      huinoremark: '',
      huinostatus: '2',
      huinotongid: '',
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Gongyewulist()
    this.Types()
    this.Getrenlist()
  },
  methods: {
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Gongyewulist()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Gongyewulist()
    },

    // 回款列表
    huilistguanbi() {
      this.huimorenguanbi()
    },
    // 确定拒绝
    noaddgoto() {
      this.Caihuishenju()
    },

    // 拒绝弹窗取消
    notongquxiao() {
      this.huinoremark = ''
      this.huinotongid = ''
      this.huinostatus = '2'
      this.noshen = false
    },

    // 拒绝窗口默认关闭
    shennotongClose() {
      this.notongquxiao()
    },

    // 回票审核确认通过
    huitongque() {
      this.Caihuishentong()
    },

    // 回票审核通过取消
    huitongqu() {
      this.loshenss = false
      this.huitongremark = ''
      this.huitongnext_user = ''
      this.huitongid = ''
    },
    // 通过默认取消
    tongmoren() {
      this.huitongqu()
    },
    // 回票重选
    huichongxuan() {
      this.huiauth_status = '-1'
      this.huishenuser = ''
      this.huisupplier = ''
      this.huitimeFrom = []
      this.huifrom_time = ''
      this.huiend_time = ''
      this.huifrom_amount = ''
      this.huiend_amount = ''
      this.huijine1 = ''
      this.huijine2 = ''
      this.huishenapply_user = ''
      this.huigongyingid = ''
      this.huitableData = []
      this.Caidanhuilist()
    },

    // 回票列表默认关闭
    huimorenguanbi() {
      this.huijiluVisible = false
      this.huichongxuan()
    },

    // 回票状态筛选
    huizhuang(e) {
      this.Caidanhuilist()
    },

    // 付拒确定
    fujuqueding() {
      this.Caifushenjujue()
    },
    // 付拒取消
    fujuquxiao() {
      this.fujuvisible = false
      this.fujuid = ''
      this.fujuremark = ''
      this.fujustatus = ''
    },

    // 付拒默认关闭
    fujumorenguan() {
      this.fujuquxiao()
    },

    // 复审默认窗口关闭
    fushenmorenguan() {
      this.fushenquxiao()
    },

    // 复审取消按钮
    fushenquxiao() {
      this.abc = []
      this.fushenform.images = []
      this.fushenform.next_user = ''
      this.fushenform.status = ''
      this.fushenform.remark = ''
      this.fushenform.id = ''
      this.fukuanshenheVisible = false
    },

    // 付审确定通过
    fushenqueding() {
      this.Caifushentong()
    },

    // 付款列表弹窗关闭
    fulistguanbi() {
      this.fujiluVisible = false
      this.chongxuan()
    },

    // 付款审核按钮
    fukuanshen(id) {
      this.fushenform.id = id
      this.fushenform.status = '1'
      this.fukuanshenheVisible = true
    },

    fukuanju(id) {
      this.fujuid = id
      this.fujuvisible = true
      this.fujustatus = '2'
    },

    // 回票审核按钮
    huikuanshen(id) {
      this.huitongid = id
      this.loshenss = true
    },

    // 回票拒绝按钮
    huikuanju(id) {
      this.huinotongid = id
      this.noshen = true
    },

    // 前
    qian(val) {
      this.from_amount = val * 100
    },

    // 后
    hou(val) {
      this.end_amount = val * 100
    },

    // 金额确定筛选
    sosuofun() {
      if (this.from_amount >= this.end_amount) {
        this.$message({
          message: '结束金额必须大于开始金额',
          type: 'warning'
        })
      } else {
        this.Caidanfulist()
      }
    },

    huisosuofun() {
      if (this.huifrom_amount >= this.huiend_amount) {
        this.$message({
          message: '结束金额必须大于开始金额',
          type: 'warning'
        })
      } else {
        this.Caidanhuilist()
      }
    },

    // 时间
    fugettime(month) {
      if (month !== null) {
        this.fufrom_time = month ? month[0] : ''
        this.fuend_time = month ? month[1] : ''
        this.Caidanfulist()
      } else {
        this.fufrom_time = ''
        this.fuend_time = ''
      }
    },

    // 前
    huiqian(val) {
      this.huifrom_amount = val * 100
      console.log(this.huifrom_amount)
    },

    // 后
    huihou(val) {
      this.huiend_amount = val * 100
      console.log(this.huiend_amount)
    },

    // 时间
    huigettime(month) {
      if (month !== null) {
        this.huifrom_time = month ? month[0] : ''
        this.huiend_time = month ? month[1] : ''
        this.Caidanhuilist()
      } else {
        this.fufrom_time = ''
        this.fuend_time = ''
      }
    },

    // 付款记录
    fujilu() {
      this.fujiluVisible = true
      this.Caidanfulist()
    },

    // 回票记录
    huijilu() {
      this.huijiluVisible = true
      this.Caidanhuilist()
    },

    // 审批状态选中
    chazhuang(e) {
      this.Caidanfulist()
    },

    // 新增付款申请确定
    xinfuque() {
      if (this.dform.auth_user == '') {
        this.$message({
          message: '请选择审核人',
          type: 'warning'
        })
      } else if (this.dform.pay_list.length <= 0) {
        this.$message({
          message: '请勾选数据',
          type: 'warning'
        })
      } else {
        this.Xiangfukuanya()
      }
    },

    // 新增回票申请确定
    xinhuique() {
      if (this.huiform.return_list.length <= 0) {
        this.$message({
          message: '请勾选数据',
          type: 'warning'
        })
      } else if (this.huiform.auth_user == '') {
        this.$message({
          message: '请选择审核人',
          type: 'warning'
        })
      } else if (this.huiform.bill_type == '') {
        this.$message({
          message: '请选择发票类型',
          type: 'warning'
        })
      } else if (this.huiform.images == '') {
        this.$message({
          message: '请上传回票截图',
          type: 'warning'
        })
      } else if (this.huiform.return_list === []) {
        this.$message({
          message: '请勾选数据',
          type: 'warning'
        })
      } else {
        this.huipiaoya()
      }
    },

    // 付款窗口默认关闭
    fuguan() {
      this.fujiluVisible = false
      this.auth_status = '-1'
      this.supplier_id = ''
      this.apply_user = ''
      this.pay_user = ''
      this.from_time = ''
      this.end_time = ''
      this.from_amount = ''
      this.end_amount = ''
      this.fuuser = ''
      this.fusupplier = ''
      this.shenuser = ''
      this.futableData = []
      this.fusupplier_id = ''
      this.Caidanfulist()
    },

    chongxuan() {
      this.auth_status = '-1'
      this.supplier_id = ''
      this.apply_user = ''
      this.pay_user = ''
      this.from_time = ''
      this.end_time = ''
      this.from_amount = ''
      this.end_amount = ''
      this.fuuser = ''
      this.fusupplier = ''
      this.shenuser = ''
      this.futableData = []
      this.fusupplier_id = ''
      this.jine1 = ''
      this.jine2 = ''
      this.Caidanfulist()
    },

    // 新增付款申请弹窗取消
    xinfuquxiao() {
      this.xinfuVisible = false
      this.dform.auth_user = ''
      this.dform.pay_list = []
      this.neifulist = []
    },

    // 新增回票申请取消
    xinhuiquxiao() {
      this.xinhuiVisible = false
      this.huiform.auth_user = ''
      this.huiform.bill_type = ''
      this.huiform.return_list = []
      this.neifulist = []
      this.goumai_fapiaos = []
      this.huiform.images = ''
    },

    // 新增付款弹窗默认关闭
    xinmoren() {
      this.xinfuquxiao()
    },

    // 新增回票默认弹窗
    huimoren() {
      this.xinhuiquxiao()
    },

    // 移除提示
    huibeforeRemove(file, fileList, re) {
      return this.$confirm(`确定移除 发票附件吗?`)
    },
    fubeforeRemove(file, fileList, re) {
      return this.$confirm(`确定移除 ${file.name}吗?`)
    },

    async huihttpRequest(options) {
      //   console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data)
      switch (this.steupdata) {
        case '发票附件':
          this.goumai_fapiaos.push({ name: res.data[0].name, url: res.data[0].url })
          this.huiform.images = res.data[0].url
          //   console.log(this.dform);
          break
      }
      this.addimgtype = false
    },

    async fushenhttpRequest(options) {
      //   console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data)
      switch (this.steupdata) {
        case '发票附件':
          this.abc.push({ name: res.data[0].name, url: res.data[0].url })
          this.fushenform.images.push(res.data[0].url)
          break
      }
      this.addimgtype = false
    },

    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },

    // 删除图片
    huihandleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
          this.huiform.images = ''
          this.goumai_fapiaos = []
        }
      })
    },

    fushenhandleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
          // this.fushenform.images = []
        }
      })
      this.fushenform.images.forEach((item, i) => {
        if (item === file.url) {
          this.fushenform.images.splice(i, 1)
        }
      })
    },

    zhengendhandlePreview(file) {},
    zhengendhandleExceed(files, fileList) {},

    // 不输入金额没办法选中
    selectable(row) {
      if (row.hasOwnProperty('pay_amount')) {
        return true
      } else {
        return false
      }
    },
    huiselectable(row) {
      if (row.hasOwnProperty('return_money')) {
        return true
      } else {
        return false
      }
    },

    // 多选框选中数据    过滤出数组中想要的对象
    handleSelectionChangezz(selection) {
      console.log(selection)
      this.dform.pay_list = selection
      // this.dform.pay_list = selection.map(item => ({
      //   purchase_id: item.purchase_id,
      //   pay_amount: item.pay_amounts
      // }))
    },

    // 回票多选选中数据
    huihandleSelectionChangezz(selection) {
      this.huiform.return_list = selection
    },

    // 回票选中数据

    // 输入框验证
    feiinputCheck(value) {
      return value
        .replace(/^0[0-9]+/, val => val[1])
        .replace(/^(\.)+/, '')
        .replace(/[^\d.]/g, '')
        .replace(/\.+/, '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },

    // 新增付款信息按钮
    xinfubtn(id) {
      this.dform.supplier_id = id
      this.Gongsoutian(id)
      this.Caigoudanlists(id)
      this.xinfuVisible = true
    },

    // 新增回票按钮
    xinhuibtn(id) {
      this.huiform.supplier_id = id
      this.Gongsoutian(id)
      this.Caigoudanlists(id)
      this.xinhuiVisible = true
    },

    // 日期选中事件
    gettime(timeFrom) {
      if (timeFrom !== null) {
        this.from_time = timeFrom ? timeFrom[0] : ''
        this.end_time = timeFrom ? timeFrom[1] : ''
        this.Gongyewulist()
      } else {
        this.from_time = ''
        this.end_time = ''
        this.Gongyewulist()
      }
    },

    // 输入框清空触发的事件
    handleEmpty() {
      this.supplier = ''
      this.Gongyewulist()
    },

    // 模糊搜索选中
    handleSelect(e) {
      this.supplier = e.supplier
      this.Gongyewulist()
    },

    // 新增付款申请供应商模糊搜索
    fuhandleSelect(e) {
      this.fusupplier_id = e.id
      this.Caidanfulist()
    },

    // 回票申请人选中
    huishenhandleSelect(e) {
      this.huishenapply_user = e.value
      this.Caidanhuilist()
    },

    // 供应商搜索
    validateCounts(queryString, cb) {
      this.Cang(queryString, cb)
    },

    // 供应商搜索
    huivalidateCounts(queryString, cb) {
      this.Cang(queryString, cb)
    },

    // 货物类型选中
    typechange() {
      this.Gongyewulist()
    },

    // 搜索负责人后的数据
    caivalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },
    // 搜索负责人后的数据
    shenvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 搜索负责人后的数据
    huisvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 付款选中申请人
    caihandleSelect(e) {
      this.pay_user = e.value
      this.Caidanfulist()
    },

    // 选中申请人
    shenhandleSelect(e) {
      this.apply_user = e.value
      this.Caidanfulist()
    },

    // 选中供应商
    huihandleSelect(e) {
      this.huigongyingid = e.id
      this.Caidanhuilist()
    },

    // 请求区
    async Gongyewulist() {
      const { data } = await Gongyewu({ name: this.supplier, type: this.type, from_time: this.from_time, end_time: this.end_time, is_contract: '1', ...this.pageData })
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 模糊搜索
    async Cang(name, cd) {
      const { data } = await Gongyewu({ name })
      this.userlist = data.data.list
      cd(data.data.list)
    },

    // 供应货物类型
    async Types() {
      const { data } = await Type()
      this.trashtypeData = data.data
    },

    // 供应商自动填充的信息
    async Gongsoutian(id) {
      const { data } = await Gongsou({ supplier_id: id })
      this.gongxiang = data.data[0]
    },

    // 采购单列表
    async Caigoudanlists(id) {
      const { data } = await Caigoudanlist({ supplier_id: id })
      this.neifulist = data.data.data
    },

    // 审核人员列表
    async Getrenlist() {
      const { data } = await Shenheren()
      this.renlist = data.data
    },

    // 详情付款明细提交
    async Xiangfukuanya() {
      const { data } = await Xiangfukuan({ ...this.dform })
      this.getanpostfun(data, '新增', this.xinmoren())
    },

    // 详情付款明细提交
    async huipiaoya() {
      const { data } = await huipiaotijiao({ ...this.huiform })
      this.getanpostfun(data, '新增', this.huimoren())
    },

    // 采购人
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.caigoulist = data.data
      cd(data.data)
    },

    // 采购单付款项列表
    async Caidanfulist() {
      const { data } = await Caidanfu({
        auth_status: this.auth_status,
        supplier_id: this.fusupplier_id,
        from_time: this.fufrom_time,
        end_time: this.fuend_time,
        from_amount: this.jine1,
        end_amount: this.jine2,
        apply_user: this.apply_user,
        pay_user: this.pay_user
      })
      this.futableData = data.data
    },

    // 采购单付款审核通过
    async Caifushentong() {
      const { data } = await Caifushen({ ...this.fushenform })
      this.getanpostfun(data, '审核通过', this.fushenmorenguan(), this.Caidanfulist())
    },

    // 付款拒绝
    async Caifushenjujue() {
      const { data } = await Caifushen({ id: this.fujuid, status: this.fujustatus, remark: this.fujuremark })
      this.getanpostfun(data, '审核拒绝', this.fujumorenguan(), this.Caidanfulist())
    },

    // 采购单回票列表
    async Caidanhuilist() {
      const { data } = await Caidanhui({
        supplier_id: this.huigongyingid,
        auth_status: this.huiauth_status,
        apply_user: this.huishenapply_user,
        from_time: this.huifrom_time,
        end_time: this.huiend_time,
        from_amount: this.huifrom_amount,
        end_amount: this.huiend_amount
      })
      this.huitableData = data.data
    },

    // 回票审核通过
    async Caihuishentong() {
      const { data } = await Caihuishen({ id: this.huitongid, status: this.huitongstatus, remark: this.huitongremark, next_user: this.huitongnext_user })
      this.getanpostfun(data, '审核通过', this.tongmoren(), this.Caidanhuilist())
    },

    // 回票审核拒绝
    async Caihuishenju() {
      const { data } = await Caihuishen({ id: this.huinotongid, status: this.huinostatus, remark: this.huinoremark })
      this.getanpostfun(data, '审核拒绝', this.shennotongClose(), this.Caidanhuilist())
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style scoped>
.title {
  display: flex;
  align-items: center;
}
.title-one {
  margin-right: 15px;
  margin-left: 15px;
}
.top {
  display: flex;
  align-items: center;
}
.tans {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.let {
  width: 70px;
  margin-left: 15px;
  margin-right: 10px;
}
.foot {
  width: 200px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 15px;
}
.top-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.title-two {
  display: flex;
  align-items: center;
  width: 300px;
}
.shai {
  width: 110px;
}
.sors {
  display: flex;
  align-items: center;
}
.tishi {
  margin-right: 15px;
  color: red;
}
.title-ones {
  width: 70px;
}
.el-input__inner {
  width: 217px !important;
}
.herd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aaass {
  box-sizing: border-box;
}
.picsya {
  position: relative;
}
.span {
  font-weight: 300;
  position: absolute;
  top: 25px;
  left: 18px;
  color: #545c64;
  font-size: 30px;
  z-index: 10000;
  pointer-events: none;
}
.lets {
  width: 100px;
}
</style>
import axios from '@/api/index'

//  供应商业务列表
export const Gongyewu = data => {
  return axios.request({
    url: `/storehouse/supplier/work/list`,
    method: 'post',
    data
  })
}

// 供应商搜索 
export const Gongsou = params => {
  return axios.request({
    url: '/storehouse/supplier/search',
    method: 'get',
    params
  })
}

// 采购合同列表 
export const Caigouhelist = data => {
  return axios.request({
    url: `/storehouse/purchase/contract/list`,
    method: 'post',
    data
  })
}

// 采购单合同审核 
export const Caihe = data => {
  return axios.request({
    url: `/storehouse/purchase/contract/auth`,
    method: 'post',
    data
  })
}


// 采购单项付款列表 
export const Caidanfu = data => {
  return axios.request({
    url: `/storehouse/purchase/pay/list`,
    method: 'post',
    data
  })
}

// 采购单付款审核 
export const Caifushen = data => {
  return axios.request({
    url: `/storehouse/purchase/pay/auth`,
    method: 'post',
    data
  })
}


// 采购单回票列表 
export const Caidanhui = data => {
  return axios.request({
    url: `/storehouse/purchase/return/list`,
    method: 'post',
    data
  })
}

// 采购单回票审核 
export const Caihuishen = data => {
  return axios.request({
    url: `/storehouse/purchase/return/auth`,
    method: 'post',
    data
  })
}